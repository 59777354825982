<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col>
          <b-card>
            <b-row>
              <b-col>
                <b-form-group label="Periode" label-cols-md="3">
                  <b-form-select
                    :options="periode"
                    type="text"
                    @change="setPeriode25()"
                    v-model="data.periode"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Tahun" label-cols-md="3">
                  <b-form-select
                    :options="thn"
                    type="text"
                    @change="setPeriode25()"
                    v-model="data.tahun"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Mulai" label-cols-md="3">
                  <date-picker
                    style="width: 100%"
                    disabled
                    v-model="data.tglAwal"
                    :placeholder="holders(data.tglAwal)"
                  ></date-picker>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Akhir" label-cols-md="3">
                  <date-picker
                    style="width: 100%"
                    disabled
                    v-model="data.tglAkhir"
                    :placeholder="holders(data.tglAkhir)"
                  ></date-picker>
                </b-form-group>
              </b-col>
              <!-- <b-col>
                <b-button variant="primary mr-1" @click="getData()"
                  >Lihat Laporan</b-button
                > -->
              <!-- <b-button variant="success" @click="getData()"
                  >Simpan Laporan</b-button
                > -->
              <!-- </b-col> -->
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="Mitra" label-cols-md="3">
                  <b-form-select
                    :options="mitra"
                    type="text"
                    @change="getKoordinator()"
                    v-model="data.mitraId"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Koordinator" label-cols-md="3">
                  <b-form-select
                    :options="koordinator"
                    type="text"
                    @change="getCabang()"
                    v-model="data.koordinatorId"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Cabang" label-cols-md="3">
                  <b-form-select
                    :options="cabang"
                    type="text"
                    @change="getCapem()"
                    v-model="data.cabangId"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Capem" label-cols-md="3">
                  <b-form-select
                    :options="capem"
                    type="text"
                    v-model="data.capemId"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <!-- <b-col> -->
              <!-- <b-button variant="primary mr-1" @click="getData()"
                  >Lihat Laporan</b-button
                > -->
              <!-- <b-button variant="success" @click="getData()"
                  >Simpan Laporan</b-button
                > -->
              <!-- </b-col> -->
            </b-row>
            <b-row>
              <b-col md="2"
                >Total Gaji Karyawan Periode
                {{ moment(data.tglAwal).format("LL") }} s/d
                {{ moment(data.tglAkhir).format("LL") }}</b-col
              >
              <b-col md="2"
                ><strong>Rp. {{ convert(sumGaji) }}</strong></b-col
              >
              <b-col md="2">
                <b-button variant="danger mr-1" @click="reset()" block
                  >Reset</b-button
                >
              </b-col>
              <b-col md="2">
                <b-button variant="primary mr-1" @click="setPeriode1()" block
                  >Tanggal 1</b-button
                >
              </b-col>
              <b-col md="2">
                <b-button variant="primary mr-1" @click="setPeriode25()" block
                  >Tanggal 25</b-button
                >
              </b-col>
              <b-col md="2">
                <b-button variant="primary mr-1" @click="getExcel()" block
                  >Excel</b-button
                >
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-card header-tag="header" header-bg-variant="dark">
            <template #header>
              <h5 class="mb-0" style="color: #fff">
                <strong>Laporan Gaji</strong>
              </h5>
            </template>

            <b-row>
              <b-col md="3">
                <!-- <b-button @click="cetakExcel()" variant="success"
                  >Export Excel</b-button
                > -->
                <b-form-group
                  label="Per Halaman"
                  label-for="per-page-select"
                  label-cols-md="6"
                  label-align-md="left"
                  label-size="md"
                  style="background-color: "
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="md"
                  ></b-form-select>
                </b-form-group>
              </b-col>

              <b-col md="6" offset-md="3">
                <b-form-group
                  label="Cari"
                  label-for="filter-input"
                  label-cols-md="3"
                  label-align-md="right"
                  label-size="md"
                >
                  <b-input-group size="md">
                    <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Ketik disini untuk mencari ..."
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button
                        :disabled="!filter"
                        @click="filter = ''"
                        variant="danger"
                        >Hapus</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-table
                  :items="items"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  stacked="md"
                  responsive
                  show-empty
                  small
                  @filtered="onFiltered"
                  bordered
                  striped
                  hover
                  :busy="tableBusy"
                >
                  <template #cell(no)="item">
                    {{ item.index + 1 }}
                  </template>

                  <template #cell(gaji)="item">
                    {{ convert(item.item.gaji) }}
                  </template>
                  <template #cell(totalAsuransi)="item">
                    {{ convert(item.item.totalAsuransi) }}
                  </template>
                  <template #cell(totalTunjangan)="item">
                    {{ convert(item.item.totalTunjangan) }}
                  </template>
                  <template #cell(totalInsentif)="item">
                    {{ convert(item.item.totalInsentif) }}
                  </template>
                  <template #cell(totalLembur)="item">
                    {{ convert(item.item.totalLembur) }}
                  </template>
                  <template #cell(totalPotongan)="item">
                    {{ convert(item.item.totalPotongan) }}
                  </template>
                  <template #cell(totalDenda)="item">
                    {{ convert(item.item.totalDenda) }}
                  </template>
                  <template #cell(takeHomePay)="item">
                    Rp . {{ convert(item.item.takeHomePay) }}
                  </template>
                </b-table>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="5" offset-md="7">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import "moment/locale/id";
export default {
  components: {
    DatePicker,
  },
  name: "laporanCustomer",
  data() {
    return {
      moment,
      mitra: [],
      koordinator: [],
      cabang: [],
      capem: [],
      items: [
        {
          no: "-",
          cus: "-",
          kat: "-",
          pic: "-",
          noIdentitas: "-",
          noTlpn: "-",
          bank: "-",
          noRek: "-",
        },
      ],
      thn: [],
      periode: [
        { value: "01", text: "Januari" },
        { value: "02", text: "Februari" },
        { value: "03", text: "Maret" },
        { value: "04", text: "April" },
        { value: "05", text: "Mei" },
        { value: "06", text: "Juni" },
        { value: "07", text: "Juli" },
        { value: "08", text: "Agustus" },
        { value: "09", text: "September" },
        { value: "10", text: "Oktober" },
        { value: "11", text: "November" },
        { value: "12", text: "Desember" },
      ],
      data: {
        periode: "",
        tahun: "",
        tglAwal: "",
        tglAkhir: "",
        mitraId: "",
        koordinatorId: "",
        cabangId: "",
        capemId: "",
        date: "",
      },
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "namaKaryawan",
          label: "Nama Karyawan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "gaji",
          label: "Gaji",
          sortable: true,
          sortDirection: "desc",
          class: "text-right",
        },

        {
          key: "totalTunjangan",
          label: "Total Tunjangan",
          sortable: true,
          sortDirection: "desc",
          class: "text-right",
        },

        {
          key: "totalInsentif",
          label: "Total Insentif",
          sortable: true,
          sortDirection: "desc",
          class: "text-right",
        },

        {
          key: "totalLembur",
          label: "Total Lembur",
          sortable: true,
          sortDirection: "desc",
          class: "text-right",
        },

        {
          key: "totalPotongan",
          label: "Total Potongan",
          sortable: true,
          sortDirection: "desc",
          class: "text-right",
        },
        {
          key: "totalAsuransi",
          label: "Asuransi",
          sortable: true,
          sortDirection: "desc",
          class: "text-right",
        },
        {
          key: "totalDenda",
          label: "Denda",
          sortable: true,
          sortDirection: "desc",
          class: "text-right",
        },
        {
          key: "takeHomePay",
          label: "THP",
          sortable: true,
          sortDirection: "desc",
          class: "text-right",
        },
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: ['namaKaryawan'],
      tableBusy: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    sumGaji() {
      let x = 0;
      let a;
      for (a of this.items) {
        // console.log(a);
        x += a.takeHomePay;
      }
      return x;
    },
  },
  mounted() {
    this.data.periode = moment().format("MM");
    this.data.tahun = moment().format("YYYY");
    this.getoptions();
    this.setPeriode25();
    this.getMitra();
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getoptions() {
      for (let i = 2000; i < 2050; i++) {
        this.thn.push(i);
      }
    },
    async getMitra() {
      let mitra = await axios.get(ipBackend + "mitra/list");
      // console.log(mitra.data.data)
      this.mitra = mitra.data.data.map((item) => {
        return { ...item, value: item.id, text: item.namaMitra };
      });
      this.mitra.unshift({ value: "", text: "" });
    },
    async getKoordinator() {
      for (let i = 0; i < this.mitra.length; i++) {
        if (this.data.mitraId == this.mitra[i].id) {
          // console.log(this.mitra[i].id, this.data.mitraId);
          this.data.date = this.mitra[i].tanggalClosing;
        }
      }
      let koordinator = await axios.get(
        ipBackend + "koordinator/listKoordinatorByMitraId/" + this.data.mitraId
      );
      // console.log(koordinator.data.data, 'koor')
      this.koordinator = koordinator.data.data.map((item) => {
        return {
          ...item,
          value: item.koordinatorId,
          text: item.namaKoordinator,
        };
      });
      this.koordinator.unshift({ value: "", text: "" });
    },
    async getCabang() {
      let cabang = await axios.get(
        ipBackend +
          "cabang/listCabangByKoordinatorId/" +
          this.data.koordinatorId
      );
      // console.log(cabang.data.data,'cabang')
      this.cabang = cabang.data.data.map((item) => {
        return { ...item, value: item.cabangId, text: item.namaCabang };
      });
      this.cabang.unshift({ value: "", text: "" });
      this.data.capem = "";
    },
    async getCapem() {
      let capem = await axios.get(
        ipBackend + "capem/listCapemByCabangId/" + this.data.cabangId
      );
      // console.log(capem.data.data, 'capem')
      this.capem = capem.data.data.map((item) => {
        return { ...item, value: item.capemId, text: item.namaCapem };
      });
      this.capem.unshift({ value: "", text: "" });
    },
    holders(x) {
      if (x) {
        return moment(x).format("LL");
      } else {
        return "";
      }
    },
    convert(x) {
      if (x) {
        return x.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      }
    },
    reset() {
      let vm = this;
      let p = vm.data.periode;
      let t = vm.data.tahun;
      if (p && t) {
        let q = moment(`${t}${p}25`).format();
        let r = moment(q).subtract(1, "M").add(1, "days").format();
        vm.data.tglAkhir = q;
        vm.data.tglAwal = r;
        vm.data.mitraId = "";
        vm.data.koordinatorId = "";
        vm.data.cabangId = "";
        vm.data.capemId = "";
      }
      vm.getData25();
    },
    setPeriode25() {
      console.log;
      let vm = this;
      vm.data.date = "25";
      let p = vm.data.periode;
      let t = vm.data.tahun;
      if (p && t) {
        let q = moment(`${t}${p}${vm.data.date}`).format();
        let r = moment(q).subtract(1, "M").add(1, "days").format();
        vm.data.tglAkhir = q;
        vm.data.tglAwal = r;
        vm.getData25();
      }
    },
    setPeriode1() {
      let vm = this;
      vm.data.date = "01";
      let p = vm.data.periode;
      let t = vm.data.tahun;
      if (p && t) {
        let q = moment(`${t}${p}${vm.data.date}`).format();
        let r = moment(q).add(1, "M").subtract(1, "days").format();
        vm.data.tglAkhir = r;
        vm.data.tglAwal = q;
        vm.getData1();
      }
    },
    async getData25() {
      let vm = this;
      vm.tableBusy = true;
      // console.log(vm.data);
      let laporan = await axios.post(
        ipBackend + "dataKaryawan/rekapTanggal25",
        vm.data
      );
      // console.log(laporan.data.data, "lap");
      vm.items = laporan.data.data.map((item) => {
        item.asuransi.push({
          namaAsuransi: "KetenagaKerjaan",
          potonganAsuransi: item.bpjsKetenagaKerjaan,
        });
        if (item.bpjsLainnya) {
          item.asuransi.push({
            namaAsuransi: "Kesehatan",
            potonganAsuransi: item.bpjsKesehatan + item.bpjsLainnya,
          });
        } else {
          item.asuransi.push({
            namaAsuransi: "Kesehatan",
            potonganAsuransi: item.bpjsKesehatan,
          });
        }

        item.gaji = 0;
        item.totalHadir = 0;
        item.totalTunjangan = 0;
        item.totalPotongan = 0;
        item.totalAsuransi = 0;
        item.totalDenda = 0;
        item.totalInsentif = 0;
        item.totalLembur = 0;
        if (item.proret > 0) {
          item.gaji = item.proret;
        } else {
          item.gaji = item.nominalGajiKaryawan;
        }

        for (let a of item.lembur) {
          item.totalLembur += Number(a.nominal);
        }

        for (let a of item.tunjangan) {
          item.totalTunjangan += Number(a.nominalTunjangan);
        }
        for (let b of item.potongan) {
          item.totalPotongan += Number(b.nominalPotongan);
        }
        for (let c of item.asuransi) {
          item.totalAsuransi += Number(c.potonganAsuransi);
        }
        if (item.absensi.length) {
          let x = Number(item.nominalGajiKaryawan / 25);
          item.totalHadir =
            Number(item.absensi[0].totalBerangkat) - Number(item.totalIjin);
          item.totalDenda += Number(item.totalIjin) * x;
          item.totalDenda += Number(item.absensi[0].istirahatTelat) * 25000;
          item.totalDenda += Number(item.absensi[0].keluarTelat) * 25000;
          item.totalDenda += Number(item.absensi[0].masukTelat) * 25000;
        }
        for (let d of item.tunjanganTidakTetap) {
          if (item.totalIjin) {
            d.insentif = d.maksimalTunjangan - d.nominalTunjanganTidaktetap * item.totalIjin
          } else {
            d.insentif = Number(d.maksimalTunjangan);
          }
          item.totalInsentif += d.insentif;
        }
        item.takeHomePay = Math.floor(
          item.gaji +
            item.totalInsentif +
            item.totalLembur +
            item.totalTunjangan -
            item.totalPotongan -
            item.totalAsuransi -
            item.totalDenda
        );
        // console.log(item.lembur,'<<')
        return item;
      });
      vm.totalRows = vm.items.length;
      vm.tableBusy = false;
    },
    async getData1() {
      let vm = this;
      vm.tableBusy = true;
      // console.log(vm.data);
      let laporan = await axios.post(
        ipBackend + "dataKaryawan/rekapTanggal1",
        vm.data
      );
      console.log(laporan.data.data, "lap");
      vm.items = laporan.data.data.map((item) => {
        item.asuransi.push({
          namaAsuransi: "KetenagaKerjaan",
          potonganAsuransi: item.bpjsKetenagaKerjaan,
        });
        if (item.bpjsLainnya) {
          item.asuransi.push({
            namaAsuransi: "Kesehatan",
            potonganAsuransi: item.bpjsKesehatan + item.bpjsLainnya,
          });
        } else {
          item.asuransi.push({
            namaAsuransi: "Kesehatan",
            potonganAsuransi: item.bpjsKesehatan,
          });
        }

        item.gaji = 0;
        item.totalHadir = 0;
        item.totalTunjangan = 0;
        item.totalPotongan = 0;
        item.totalAsuransi = 0;
        item.totalDenda = 0;
        item.totalInsentif = 0;
        if (item.proret > 0) {
          item.gaji = item.proret;
        } else {
          item.gaji = item.nominalGajiKaryawan;
        }

        for (let a of item.tunjangan) {
          item.totalTunjangan += Number(a.nominalTunjangan);
        }
        for (let b of item.potongan) {
          item.totalPotongan += Number(b.nominalPotongan);
        }
        for (let c of item.asuransi) {
          item.totalAsuransi += Number(c.potonganAsuransi);
        }
        if (item.absensi.length) {
          let x = Number(item.nominalGajiKaryawan / 25);
          item.totalHadir =
            Number(item.absensi[0].totalBerangkat) - Number(item.totalIjin);
          item.totalDenda += Number(item.totalIjin) * x;
          item.totalDenda += Number(item.absensi[0].istirahatTelat) * 25000;
          item.totalDenda += Number(item.absensi[0].keluarTelat) * 25000;
          item.totalDenda += Number(item.absensi[0].masukTelat) * 25000;
        }
        for (let d of item.tunjanganTidakTetap) {
          item.totalInsentif +=
            Number(d.nominalTunjanganTidaktetap) * Number(item.totalHadir);
        }
        item.takeHomePay = Math.floor(
          item.gaji +
            item.totalInsentif +
            item.totalTunjangan -
            item.totalPotongan -
            item.totalAsuransi -
            item.totalDenda
        );
        return item;
      });
      vm.totalRows = vm.items.length;
      vm.tableBusy = false;
    },
    async getExcel() {
      let vm = this;
      vm.tableBusy = true;
      console.log(vm.data);
      let tglAwal = moment(vm.data.tglAwal).format("YYYY-MM-DD");
      let tglAkhir = moment(vm.data.tglAkhir).format("YYYY-MM-DD");
      let x = `?date=${vm.data.date}&tglAwal=${tglAwal}&tglAkhir=${tglAkhir}`;
      if (vm.data.mitraId) {
        x += `&mitraId=${vm.data.mitraId}`;
      }
      if (vm.data.koordinatorId) {
        x += `&koordinatorId=${vm.data.koordinatorId}`;
      }
      if (vm.data.cabangId) {
        x += `&cabangId=${vm.data.cabangId}`;
      }
      if (vm.data.capemId) {
        x += `&capemId=${vm.data.capemId}`;
      }
      // let laporan = await axios.get(
      //   ipBackend + "downloadExcel/downloadDraftGaji" + x
      // ).then(wind)
      await window.open(
        ipBackend + "downloadExcel/downloadDraftGaji" + x,
        "_blank"
      );
      // console.log(laporan.data.data, "lap");
      // vm.items = laporan.data.data.map((item) => {
      //   item.asuransi.push({
      //     namaAsuransi: "KetenagaKerjaan",
      //     potonganAsuransi: item.bpjsKetenagaKerjaan,
      //   });
      //   if (item.bpjsLainnya) {
      //     item.asuransi.push({
      //       namaAsuransi: "Kesehatan",
      //       potonganAsuransi: item.bpjsKesehatan + item.bpjsLainnya,
      //     });
      //   } else {
      //     item.asuransi.push({
      //       namaAsuransi: "Kesehatan",
      //       potonganAsuransi: item.bpjsKesehatan,
      //     });
      //   }

      //   item.gaji = 0;
      //   item.totalHadir = 0;
      //   item.totalTunjangan = 0;
      //   item.totalPotongan = 0;
      //   item.totalAsuransi = 0;
      //   item.totalDenda = 0;
      //   item.totalInsentif = 0;
      //   if (item.proret > 0) {
      //     item.gaji = item.proret;
      //   } else {
      //     item.gaji = item.nominalGajiKaryawan;
      //   }

      //   for (let a of item.tunjangan) {
      //     item.totalTunjangan += Number(a.nominalTunjangan);
      //   }
      //   for (let b of item.potongan) {
      //     item.totalPotongan += Number(b.nominalPotongan);
      //   }
      //   for (let c of item.asuransi) {
      //     item.totalAsuransi += Number(c.potonganAsuransi);
      //   }
      //   if (item.absensi.length) {
      //     item.totalHadir = item.absensi[0].totalBerangkat;
      //     // item.totalDenda +=
      //     //   Number(item.absensi[0].jamIstirahatKeluarTelat) * 25000;
      //     item.totalDenda += Number(item.absensi[0].istirahatTelat) * 25000;
      //     item.totalDenda += Number(item.absensi[0].keluarTelat) * 25000;
      //     item.totalDenda += Number(item.absensi[0].masukTelat) * 25000;
      //   }
      //   for (let d of item.tunjanganTidakTetap) {
      //     item.totalInsentif +=
      //       Number(d.nominalTunjanganTidaktetap) * Number(item.totalHadir);
      //   }
      //   item.takeHomePay =
      //     item.gaji +
      //     item.totalInsentif +
      //     item.totalTunjangan -
      //     item.totalPotongan -
      //     item.totalAsuransi -
      //     item.totalDenda;
      //   return item;
      // });
      vm.totalRows = vm.items.length;
      vm.tableBusy = false;
    },
    cetakExcel() {
      window.open(ipBackend + "report/reportCustomer", "_blank");
    },
  },
};
</script>
